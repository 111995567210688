.component {
  background-color: rgba(255 255 255 / 85%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease-out;
  z-index: 8;
}

.component p {
  color: theme('colors.gray.4');
  font-size: theme('fontSize.18');
  margin-top: theme('spacing.2x');
}

.loader {
  animation: spin 2000ms linear infinite;
  border: theme('spacing.1xs') solid theme('colors.gray.2');
  border-left-color: theme('colors.turmeric');
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.visible {
  opacity: 1;
  pointer-events: all;
}
