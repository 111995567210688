/**
 * Application specific variables that we can adjust based on media size
 */
:root {
  --color-background-salt: #faf7f5;
  --color-blue-light: #e4edf2;
  --color-gray-alt: #b3b3b3;
  --color-gray: #e8e8e8;
  --color-pine-light: #95caba;
  --color-sea-salt-light: #f5f2f0;
  --footer-height: 498px;
  --header-height: theme('spacing.7x');
  --store-height: theme('spacing.7x');

  /* Header plus menu height */
  --store-top: calc(var(--header-height) + var(--store-height));

  /* Screen sizes available as variables */
  --screen-xs: 320px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1536px;
}

@screen sm {
  :root {
    --footer-height: 340px;
  }
}

@screen md {
  :root {
    --footer-height: 348px;
    --header-height: 70px;
  }
}

@screen lg {
}

@screen xl {
}

@screen 2xl {
}
