.component {
  align-items: center;
  font-family: theme('fontFamily.beatrice-regular');
  font-size: theme('fontSize.14');
}

.circle {
  align-items: center;
  background-color: theme('colors.turmeric');
  border-radius: 50%;
  display: flex;
  font-size: theme('fontSize.12');
  height: 30px;
  justify-content: center;
  min-width: 30px;
  width: 30px;
}

.image {
  margin-left: 1px;
}

.store {
  height: var(--header-height);
}
