.ui-bg-gray {
  background-color: var(--color-gray);
}

/**
 * BUTTON
 */
.ui-btn {
  border: 3px solid theme('colors.forest');
  font-size: theme('fontSize.14');
  height: theme('spacing.6x');
  min-width: 240px;
  padding: 10px;
  position: relative;
}

/**
 * CONTAINER
 */
.ui-container {
  margin: auto;
  max-width: 1280px;
}

.ui-container-sm,
.ui-container-md,
.ui-container-lg,
.ui-container-xl,
.ui-container-2xl {
  margin: 0 auto;
}

.ui-container-sm {
  max-width: 640px;
}

.ui-container-md {
  max-width: 768px;
}

.ui-container-lg {
  max-width: 1024px;
}

.ui-container-xl {
  max-width: 1280px;
}

.ui-container-2xl {
  max-width: 1536px;
}

/**
 * HEADING
 */
.ui-heading {
  font-size: theme('fontSize.20');
  margin: theme('spacing.4x') 0;
  text-align: center;
  text-transform: uppercase;
}

@screen sm {
  .ui-heading {
    font-size: theme('fontSize.24');
    margin: theme('spacing.6x') 0;
  }
}

@screen md {
  .ui-heading {
    font-size: theme('fontSize.32');
    margin: theme('spacing.6x') 0;
  }
}

@screen lg {
  .ui-heading {
    font-size: theme('fontSize.32');
    margin: theme('spacing.8x') 0;
  }
}

@screen xl {
  .ui-heading {
    font-size: theme('fontSize.36');
    margin: theme('spacing.8x') 0;
  }
}

/**
 * PAGE HEADINGS
 */
.ui-page-heading {
  font-size: theme('fontSize.24');
  margin: 0;
  padding: theme('spacing.3x') 0;
}

@screen md {
  .ui-page-heading {
    font-size: theme('fontSize.32');
    padding: theme('spacing.6x') 0;
  }
}

@screen lg {
  .ui-page-heading {
    font-size: 40px;
    padding: theme('spacing.6x') 0;
  }
}

.ui-page-heading-medium {
  font-size: theme('fontSize.20');
  margin: 0;
  padding: theme('spacing.3x') 0;
}

@screen md {
  .ui-page-heading-medium {
    font-size: theme('fontSize.24');
    padding: theme('spacing.6x') 0;
  }
}

@screen lg {
  .ui-page-heading-medium {
    font-size: theme('fontSize.28');
    padding: theme('spacing.6x') 0;
  }
}

/**
 * MISCELLANEOUS
 */
.ui-box-shadow {
  box-shadow: 0 2px 4px rgba(0 0 0 / 20%);
}

.ui-gradient {
  background-image: url('/images/jpg/gradient_1.jpg');
  background-image: url('/images/webp/gradient_1.webp');
  background-repeat: no-repeat;
  background-size: 100vw 100%;
}

.ui-gradient.css {
  background-image: linear-gradient(107deg, #f2f2f2, #fff1e8 56%, #ffffec);
  background-size: 100% 100%;
}

.ui-gradient.two {
  background-image: url('/images/jpg/gradient_2.jpg');
  background-image: url('/images/webp/gradient_2.webp');
}

.ui-gradient.three {
  background-image: url('/images/jpg/gradient_3.jpg');
  background-image: url('/images/webp/gradient_3.webp');
}

.ui-highlight {
  background-image: url('/images/svg/highlight.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 0.2em;
}

.ui-rotate-text {
  transform: rotate(-3deg);
}

/**
 * CIRCLES
 */
.ui-circle {
  align-items: center;
  border: 2px solid theme('colors.gray.8');
  border-radius: 50%;
  display: flex;
  font-size: theme('fontSize.16');
  height: theme('spacing.5xs');
  justify-content: center;
  width: theme('spacing.5xs');
}

.ui-circle-thin {
  border-width: 1px;
}

/**
 * FIXED BUTTONS WE USE ON MOBILE
 */
.ui-button-fixed {
  /* background-color: rgba(255, 255, 255, 0.8); */
  border-left: unset;
  border-right: unset;
  bottom: 0;
  left: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
  position: fixed;
  right: 0;
  z-index: 9;
}

.ui-button-fixed a,
.ui-button-fixed button {
  background-color: theme('colors.turmeric');
  margin-bottom: calc(env(safe-area-inset-bottom, 0) * 0.6);
  margin-top: 0;
  text-align: center;
  width: 100%;
}

@screen md {
  .ui-button-fixed {
    display: block;
    margin: theme('spacing.4x') auto;
    position: relative;
    width: 300px;
  }
}

/**
 * DESKTOP / MOBILE (important to come late(r) in this cascade)
 */
.ui-desktop {
  display: none;
}

.ui-mobile {
  display: initial;
}

@screen md {
  .ui-desktop {
    display: initial;
  }

  .ui-mobile {
    display: none;
  }
}

.ui-border-bottom {
  border-bottom: 6px solid theme('colors.turmeric');
}

.ui-border-bottom.seaSalt {
  border-bottom-color: var(--color-sea-salt-light);
}

.ui-border-top {
  border-top: 6px solid theme('colors.turmeric');
}

.ui-border-top.seaSalt {
  border-top-color: var(--color-sea-salt-light);
}
