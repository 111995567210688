/* stylelint-disable declaration-no-important */

.Toastify__toast-container .Toastify__toast--default {
  background-color: theme('colors.turmeric');
  color: theme('colors.gray.8');
}

.tippy-popper {
  z-index: 3 !important;
}

.tippy-popper .tippy-tooltip {
  background-color: theme('colors.turmeric');
}

.tippy-popper .arrow-big {
  border-color: theme('colors.turmeric');
}

.tippy-popper[x-placement^='bottom'] [x-arrow].arrow-big {
  border-bottom-color: theme('colors.turmeric');
}

.tippy-popper[x-placement^='top'] [x-arrow].arrow-big {
  border-top-color: theme('colors.turmeric');
}

/**
 * Overrides for the default styles that Algolia provides. It's not the cleanest
 * method of styling the input boxes but it is the simplest to implement. The
 * alternative was to make a custom Algolia input so we have more control which
 * wasn't working and proved a bit of a rabbit hole.
 */

/* This gets rid of the search magnifying glass and the clear box from the
 * Algolia style.
 */
.aa-InputWrapperPrefix,
.aa-InputWrapperSuffix {
  display: none;
}

/* This is needed to override the Algolia default focus outline on their search
 * box.
 */
.aa-Form,
.aa-Form:focus-within {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

/* Keep the text in the dropdown the same as the input box. */
.aa-Panel {
  border-radius: 0;
  color: theme('colors.forest');
  font-size: theme('fontSize.12');
  margin-top: 2px;
  z-index: 2;
}

/* Makes it so we can return more results in the product query. */
:root {
  --aa-panel-max-height: 310px;
  --aa-selected-color-rgb: 183, 181, 180;
}

.aa-InputWrapper {
  height: 48px;
}

/*
 * Opted to not mix vendor overrides with our styles even if this means a bit of
 * code duplication.
 */
.aa-Input {
  background: theme('colors.white');
  border: 1px solid theme('colors.forest');
  border-radius: theme('spacing.1xs');
  font-family: 'beatrice-regular', sans-serif;
  font-size: theme('fontSize.14');
  height: 48px;
  margin-bottom: 0;
  padding-left: theme('spacing.4');
  padding-right: theme('spacing.4');
  transition: border 200ms ease-out, outline 200ms ease-out;
  width: 100%;
}

.aa-Input::placeholder {
  color: theme('colors.gray.4');
  opacity: 1;
}

/* stylelint-disable selector-no-vendor-prefix */
.aa-Input::-ms-input-placeholder {
  color: var(--color-gray);
}

.aa-Input:-ms-input-placeholder {
  color: var(--color-gray);
}
/* stylelint-enable selector-no-vendor-prefix */

.aa-Input:focus {
  outline-color: theme('colors.turmeric');
}

.type-products .aa-Input {
  border: none;
  border-radius: 0;
}

@media (max-width: 320px) {
  .aa-Input {
    font-size: theme('fontSize.14');
  }
}

@screen md {
  .aa-Input {
    font-size: theme('fontSize.16');
    padding-left: theme('spacing.8');
    padding-right: theme('spacing.8');
  }

  .aa-Panel {
    font-size: theme('fontSize.14');
  }
}

/* stylelint-disable no-duplicate-selectors */
:root {
  --swiper-theme-color: theme('colors.gray.4') !important;
}
/* stylelint-enable no-duplicate-selectors */
