/* Vendor */
@import 'react-tippy/dist/tippy.css';
@import 'react-toastify/dist/ReactToastify.css';
@import '@algolia/autocomplete-theme-classic/dist/theme.css';

/* Style Guide */
@import '@haldi/style-guide';

/* Client */
@import './index.css';

/**
 * @tailwind base;
 * @tailwind components;
 */

@tailwind utilities;
