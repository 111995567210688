.component {
  font-family: theme('fontFamily.beatrice-medium');
  font-size: theme('fontSize.14');

  /* prettier-ignore */
  padding: theme('spacing.2x') theme('spacing.2x') theme('spacing.2x') theme('spacing.2x');
  position: relative;
  text-align: center;
  transition: background 200ms ease-out;
  z-index: 9;
}

.button {
  color: theme('colors.forest');
  font-size: theme('fontSize.24');
  height: theme('spacing.5x');
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: theme('spacing.3x');
}

.component.light {
  background-color: theme('colors.gray.0');
  border-bottom: 1px solid theme('colors.gray.2');
  color: theme('colors.forest');
}

.component.pine {
  background-color: theme('colors.pine');
  color: theme('colors.forest');
}

.component.dark {
  background-color: theme('colors.forest');
  border-bottom: unset;
  color: theme('colors.white');
}

@screen md {
  .button {
    right: theme('spacing.2x');
  }
}

@screen sm {
  .component {
    padding-right: theme('spacing.2x');
  }
}
