.component {
  background-color: theme('colors.white');
  border-bottom: 1px solid theme('colors.gray.2');
  height: var(--header-height);
  overflow: visible;
  position: sticky;
  top: 0;
  z-index: 9;
}

.component:global(.bg-white) .active {
  border-color: theme('colors.forest');
  color: theme('colors.forest');
}

@screen md {
  .component.acquisition {
    background-color: transparent;
    border-bottom: none;
    height: unset;
    position: unset;
  }
}
