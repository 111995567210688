input {
  font-size: theme('fontSize.14');
}

input.ui-radio {
  appearance: unset;
  background-image: url('/images/rebrand/radio-inactive.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: none;
  height: 20px;
  padding: 20px;
  top: 0;
  width: 20px;
}

input.ui-radio:checked {
  background-image: url('/images/rebrand/radio-active.svg');
}
