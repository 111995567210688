.component {
  font-size: theme('fontSize.16');
  line-height: 1.6;
  max-height: calc(100vh - calc(100vh / 4));
  min-width: 300px;
  overflow: auto;
  padding: theme('spacing.3xs') theme('spacing.2x');
}

.component button {
  font-size: theme('fontSize.14');
  margin-top: theme('spacing.2x');
  width: 100%;
}

.component.empty {
  min-width: 200px;
}
