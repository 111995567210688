.component {
  align-items: center;
  font-size: theme('fontSize.14');
  gap: theme('spacing.2x');
  justify-content: start;
  margin-bottom: theme('spacing.2x');
}

.component p {
  font-size: theme('fontSize.14');
  margin: 0;
}

.image {
  max-width: theme('spacing.7x');
}
