.component {
  font-family: theme('fontFamily.beatrice-light');
  min-width: 200px;
}

.component p {
  font-size: theme('fontSize.14');
}

.component b {
  font-family: theme('fontFamily.beatrice-regular');
}
