.component {
  background-color: theme('colors.white');
  border-bottom: 1px solid theme('colors.gray.2');
  height: var(--header-height);
  overflow: visible;
  position: sticky;
  top: 0;
  transition: background 140ms ease-out;
  z-index: 9;
}

.component.transparent {
  background-color: transparent;
  border-bottom-color: transparent;
}

@screen md {
  .component {
    border-bottom: none;
    box-shadow: 0 2px 2px rgba(0 0 0 / 15%);
    height: var(--header-height);
  }
}
