.component {
  align-items: center;
  display: flex;
  height: var(--header-height);
  justify-content: center;
  min-width: 40px;
  position: relative;
}

.bag {
  height: theme('spacing.3xs');
  width: theme('spacing.3xs');
}

button.cta {
  font-size: theme('fontSize.14');
  margin-top: theme('spacing.2x');
  width: 100%;
}

.counter {
  font-family: theme('fontFamily.beatrice-regular');
}
