.component {
  color: theme('colors.white');
  font-family: theme('fontFamily.beatrice-regular');
  font-size: theme('fontSize.14');
  padding: theme('spacing.4x');
  position: relative;
  z-index: 1;
}

.disclaimer {
  font-family: theme('fontFamily.central-no1-light');
  font-size: 0.9em;
}

.links {
  cursor: pointer;
  flex: 2 0;
  white-space: nowrap;
}

.links a {
  color: theme('colors.white');
  display: block;
  margin-bottom: theme('spacing.1xs');
  text-decoration: none;
  transition: padding 200ms ease-out;
}

.social a {
  color: theme('colors.white');
}

.links a:hover {
  padding-left: theme('spacing.1x');
}

.logo {
  flex: 1 0;
  height: theme('spacing.4x');
  order: -1;
}

.logo svg {
  max-width: 140px;
}

.reflow {
  flex: 2 0;
}

.social {
  align-items: flex-end;
  flex: 1 0;
  justify-content: end;
}

.social img {
  height: theme('spacing.4x');
  width: theme('spacing.4x');
}

@screen md {
  .component {
    padding: 140px theme('spacing.4x') theme('spacing.4x');
  }

  .logo {
    order: unset;
    padding-top: theme('spacing.1xs');
  }

  .social img {
    height: theme('spacing.5x');
    width: theme('spacing.5x');
  }
}
