.component {
  min-width: 200px;
}

.component a,
.component button {
  color: theme('colors.forest');
  cursor: pointer;
  font-family: theme('fontFamily.beatrice-regular');
  font-size: theme('fontSize.16');
  padding: theme('spacing.1x') 0;
  text-align: left;
  text-decoration: unset;

  /* transition: color 140ms ease-in-out, padding 140ms ease-in-out; */
}

.component a.small,
.component button.small {
  font-family: theme('fontFamily.central-no1-light');
  font-size: theme('fontSize.14');
}

.component .active,
.component .active:hover {
  color: theme('colors.gray.3');
  cursor: default;

  /* padding-left: theme('spacing.1x'); */
}

.component hr {
  border-top-color: theme('colors.forest');
  margin: theme('spacing.2xs') 0;
}

.small {
  font-size: theme('fontSize.12');
  text-align: unset;
}
